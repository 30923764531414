import React from 'react'
import { stringify } from 'qs'
import { serialize } from 'dom-form-serializer'
import './Form.css'

class Form extends React.Component {
  static defaultProps = {
    name: 'Simple Form Ajax',
    subject: '', // optional subject of the notification email
    action: '',
    successMessage: 'Formulář byl úspěšně odeslán. Brzy Vás budeme kontaktovat',
    errorMessage:
      'Vyskytl se problém a Vaše zpráva nebyla odeslána, kontaktujte nás prosím prostřednictvím emailu',
  }

  state = {
    name: this.props.name,
    alert: null,
    firstname: null,
    lastname: null,
    phone: null,
    emailAddress: null,
    category: null,
    message: null,
    disabled: false,
  }

  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.state.disabled) return

    const form = e.target
    const {
      firstname,
      lastname,
      phone,
      emailAddress,
      category,
      message,
      name,
    } = this.state
    const formData = {
      firstname,
      lastname,
      phone,
      emailAddress,
      category,
      message,
      'form-name': name,
    }
    const data = formData
    this.setState({ disabled: true })
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: this.encode({ 'form-name': this.state.name, ...this.state }),
    })
      .then(res => {
        if (res.ok) {
          return res
        } else {
          throw new Error('Network error')
        }
      })
      .then(() => {
        form.reset()
        this.setState({
          disabled: false,
        })
        window.location.href = 'https://danovekontroly.cz/dekujeme-vam'
      })
      .catch(err => {
        console.error(err)
        this.setState({
          disabled: false,
          alert: this.props.errorMessage,
        })
      })
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  render() {
    const { name, subject, action } = this.props
    const {
      firstname,
      lastname,
      phone,
      emailAddress,
      message,
      category,
    } = this.state
    return (
      <form
        className="Form"
        name={name}
        method="post"
        action={action}
        onSubmit={this.handleSubmit}
        data-netlify="true"
      >
        {this.state.alert && (
          <div className="Form--Alert">{this.state.alert}</div>
        )}
        <div className="Form--Group">
          <label className="Form--Label">
            <input
              className={`Form--Input Form--InputText ${
                this.state.firstname ? 'Form--Input--NonEmpty' : ''
              }`}
              type="text"
              placeholder="Firstname"
              name="firstname"
              value={firstname}
              onChange={this.handleChange}
              required
            />
            <span>Jméno:</span>
          </label>
          <label className="Form--Label">
            <input
              className={`Form--Input Form--InputText ${
                this.state.lastname ? 'Form--Input--NonEmpty' : ''
              }`}
              type="text"
              placeholder="Lastname"
              name="lastname"
              value={lastname}
              onChange={this.handleChange}
              required
            />
            <span>Příjmení:</span>
          </label>
        </div>

        <label className="Form--Label">
          <input
            className={`Form--Input Form--InputText ${
              this.state.phone ? 'Form--Input--NonEmpty' : ''
            }`}
            type="tel"
            placeholder="Phone"
            name="phone"
            pattern="(+|[0-9]){10}"
            value={phone}
            onChange={this.handleChange}
            required
          />
          <span>Telefonní číslo:</span>
        </label>

        <div className="Form--Group">
          <label className="Form--Label">
            <input
              className={`Form--Input Form--InputText ${
                this.state.emailAddress ? 'Form--Input--NonEmpty' : ''
              }`}
              type="email"
              placeholder="Email"
              name="emailAddress"
              value={emailAddress}
              onChange={this.handleChange}
              required
            />
            <span>E-mail:</span>
          </label>
        </div>

        <label className="Form--Label">
          <textarea
            className={`Form--Input Form--Textarea Form--InputText ${
              this.state.message ? 'Form--Input--NonEmpty' : ''
            }`}
            placeholder="Message"
            name="message"
            value={message}
            onChange={this.handleChange}
            rows="10"
            required
          />
          <span>Jak Vám můžeme pomoci?</span>
        </label>

        {!!subject && <input type="hidden" name="subject" value={subject} />}
        <input type="hidden" name="form-name" value={name} />
        <input
          className="Form--Button Form--SubmitButton"
          type="submit"
          value="Odeslat"
          disabled={this.state.disabled}
        />
      </form>
    )
  }
}

export default Form
